






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FranchiseOpportunityCard extends Vue {
  @Prop()
  private readonly label!: string;
}
